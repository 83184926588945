import Vue from "vue";
import Vuetify from "vuetify/lib";
import helpers from '../utils/rupees-convert';
const plugin = {
  install () {
    Vue.prototype.$helpers = helpers
  }
}
Vue.use(plugin);
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
});
