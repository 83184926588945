let thousands = ['', 'thousand', 'lakh', 'crore'];
let digits = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
let tens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
let twenties = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

const convertNumberToWords = (number)  =>{
    if (number === undefined || number === null) return 'invalid input';

    number = number.toString().replace(/[\, ]/g, ''); // Remove commas and spaces.
    if (isNaN(number)) return 'invalid input'; // Check if the input is a valid number.

    let decimalIndex = number.indexOf('.');
    let fractionPart = decimalIndex !== -1 ? number.slice(decimalIndex + 1) : '';
    let wholePart = decimalIndex !== -1 ? number.slice(0, decimalIndex) : number;
    if (wholePart.length > 15) return 'too big'; // Handle numbers that are too large.

    let word = convertWholePartToWords(wholePart) + ' rupees'; // Convert the whole part to words.

    if (fractionPart) {
        let fractionWords = convertFractionPartToWords(fractionPart);
        word += ' and ' + fractionWords + ' paisa'; // Convert the fractional part to paisa.
    } else {
        word += ' only';
    }

    return word.replace(/\s+/g, ' ').trim(); // Remove extra spaces.
}
const convertWholePartToWords = (num) => {
    let word = '';
    let unitIndex = 0;

    // Process the number in chunks of 3 or 2 digits, depending on the unit.
    while (num.length > 0) {
        let chunkSize;
        if (unitIndex === 1 || unitIndex === 2) { // Handle thousands and lakhs
            chunkSize = 2;
        } else if (unitIndex === 3) { // Handle crores
            chunkSize = 2;
        } else { // Handle the hundreds place
            chunkSize = 3;
        }

        let chunk = num.slice(-chunkSize);
        num = num.slice(0, -chunkSize);
        
        let chunkNumber = parseInt(chunk, 10);
        if (chunkNumber > 0) {
            if (unitIndex === 0) {
                word = getWordsForChunk(chunkNumber) + ' ' + word;
            } else {
                word = getWordsForChunk(chunkNumber) + ' ' + thousands[unitIndex] + ' ' + word;
            }
        }
        unitIndex++;
    }

    return word.trim();
}

const convertFractionPartToWords = (num) => {
    let fractionNumber = parseInt(num, 10);
    if (fractionNumber === 0) return '';
    return getWordsForChunk(fractionNumber);
}

const getWordsForChunk = (chunkNumber) => {
    let chunkWord = '';
    if (chunkNumber >= 100) {
        chunkWord += digits[Math.floor(chunkNumber / 100)] + ' hundred ';
        chunkNumber = chunkNumber % 100;
    }
    if (chunkNumber >= 20) {
        chunkWord += twenties[Math.floor(chunkNumber / 10) - 2] + ' ';
        chunkNumber = chunkNumber % 10;
    }
    if (chunkNumber > 0 && chunkNumber < 10) {
        chunkWord += digits[chunkNumber] + ' ';
    } else if (chunkNumber >= 10) {
        chunkWord += tens[chunkNumber - 10] + ' ';
    }
    return chunkWord.trim();
}
export default { convertNumberToWords };