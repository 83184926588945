<template>
  <v-app backgroundColor="#673AB7">
    <NavBar></NavBar>
    <v-main transition="slide-x-transition" class="bg-body">
      <router-view></router-view>
    </v-main>
    <v-snackbar v-model="$store.getters.snackBar.active" :timeout="3000" :color="$store.getters.snackBar.color"
      :top="true" centered>
      {{ $store.getters.snackBar.message }}
      <v-btn text small dark @click="closeSnackBar()">
        Close
      </v-btn>
    </v-snackbar>
    <v-overlay v-model="$store.getters.saveLoading" class="align-center justify-center">
      <!--  -->
      <v-container>
        <v-row class="fill-height" align-content="center" justify="center" dark>
          <v-col cols="6">
            <v-progress-circular color="purple" indeterminate size="64" :width="4">
            </v-progress-circular>
          </v-col>
          <v-col class="text-subtitle-1 text-center" cols="12">
            <span class="text-h3 transition-swing font-weight-medium"> Loading...</span>
          </v-col>
        </v-row>
      </v-container>
    </v-overlay>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar";
export default {
  components: {
    NavBar,
  },

  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    closeSnackBar() {
      this.$store.commit('SET_SNACKBAR', {
        active: false,
        color: "",
        message: "",
      });
    }
  },
};
</script>
<style lang="css" scoped>
.bg-body {
  background-color: #f6f9fc;
}
</style>